<template>
    <v-col cols="12">
        <base-card>
            <v-card-title>{{ $t('admin.reports.accessToSystemMonth.title') }}</v-card-title>
            <v-card-text style="min-height: 331px">
                <apexchart
                    v-if="loaded"
                    type="area"
                    height="300"
                    :options="marketChart.chartOptions"
                    :series="marketChart.series"
                />
            </v-card-text>
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios';
import { mapGetters } from 'vuex';

export default {
    name: 'UserAccessHistory',
    props: {
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            loaded: false,
            marketChart: {
                series: [],
                chartOptions: {
                    colors: ['#2D99FF', '#83CFFF'],
                    fill: {
                        type: 'solid'
                    },
                    legend: {
                        show: true
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM / yy',
                                day: 'dd MMM',
                                hour: 'HH:mm'
                            }
                        }
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy'
                        },
                        y: {
                            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                return value + '';
                            }
                        }
                    },
                    chart: {
                        height: 300,
                        type: 'area',
                        toolbar: {
                            show: false
                        },
                        defaultLocale: 'es-ES',
                        locales: [
                            {
                                name: 'es-ES',
                                options: {
                                    months: [
                                        'Enero',
                                        'Febrero',
                                        'Marzo',
                                        'Abril',
                                        'Mayo',
                                        'Junio',
                                        'Julio',
                                        'Agosto',
                                        'Septiembre',
                                        'Octubre',
                                        'Noviembre',
                                        'Diciembre'
                                    ],
                                    shortMonths: [
                                        'Ene',
                                        'Feb',
                                        'Mar',
                                        'Abr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Ago',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dic'
                                    ],
                                    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                                    shortDays: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
                                }
                            },
                            {
                                name: 'en',
                                options: {
                                    months: [
                                        'January',
                                        'February',
                                        'March',
                                        'April',
                                        'May',
                                        'June',
                                        'July',
                                        'August',
                                        'September',
                                        'October',
                                        'November',
                                        'December'
                                    ],
                                    shortMonths: [
                                        'Jan',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec'
                                    ],
                                    days: [
                                        'Sunday',
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday'
                                    ],
                                    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                                }
                            }
                        ]
                    }
                }
            }
        };
    },
    created() {
        this.fetchDataHistory();
    },
    computed: {
        ...mapGetters('languages', ['getLang'])
    },
    methods: {
        async fetchDataHistory() {
            try {
                const purposes = await axios.get('reports/lastAccessByDay/' + this.companyId);

                // fulfill map with the data loaded
                /* const purposesCloudByDay = purposes.data.purposesCloudByDay;
                const mapPurposesCloud = {};
                for (const data of purposesCloudByDay) {
                    mapPurposesCloud[data.date] = data.count;
                } */

                const userAccess = purposes.data.userAccess;
                const mapUserAccess = {};
                for (const data of userAccess) {
                    mapUserAccess[data.date] = data.count;
                }

                // creating data for charts

                /* const serieCloud = {
                    name: this.$t("admin.dashboard.historyComponnent.serieB"),
                    data: new Array(31)
                }; */

                const serieUserAccess = {
                    name: this.$t('admin.reports.accessToSystemMonth.access'),
                    data: new Array(31)
                };

                const categories = new Array(31);

                var today = new Date();
                var priorDate = new Date();
                priorDate.setDate(today.getDate() - 31);

                for (let i = 0; priorDate <= today; i++) {
                    const date = this.dateHelper.dateToString(priorDate);

                    /* const valueCloud = mapPurposesCloud[date] ?? 0;
                    serieCloud.data[i] = valueCloud; */

                    const valueDrafts = mapUserAccess[date] ?? 0;
                    serieUserAccess.data[i] = valueDrafts;

                    // const [year, month, day] = date.split('-')
                    // const dateFormat = `${day}/${month}`;
                    categories[i] = date;

                    priorDate.setDate(priorDate.getDate() + 1);
                }

                this.marketChart.series[0] = serieUserAccess;
                // this.marketChart.series[1] = serieCloud;

                this.marketChart.chartOptions.xaxis.categories = categories;
                this.marketChart.chartOptions.chart.defaultLocale = /* this.getLang ?? */ 'es-ES';

                this.loaded = true;
                // this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.count, purposesCloudPublished.data.usersCount, 0)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error); // "invalid_credentials"
                    console.log(error.response.status);
                }
            }
        }
    }
};
</script>

<style scoped></style>
